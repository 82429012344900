import React, { useContext } from "react";
import { DocumentContext } from "~context/DocumentContext";
import CursorHoverable from "~components/CursorHoverable";

const ReachOutCTA = () => {
  const { device } = useContext(DocumentContext);

  return (
    <section className="w-full relative block pt-v6 xs:pt-v16 pb-v12">
      <div className="grid">
        <div className="grid-end-22 xs:grid-end-24 grid-start-2 xs:grid-start-1">
          <h3 className="b1">Help us raise money for mental health</h3>

          <p
            className={`mt-v2 xs:mt-v4 ${
              device && device === `desktop` ? `f3` : `f5`
            }`}
          >
            All profits from With Solace are being donated to one of Australia’s
            leading mental health organisations for supporting young Australians
            and their parents, ReachOut.
          </p>

          <h3
            className={`xs:mt-v12 ${
              device && device === `desktop` ? `f5` : `b1`
            }`}
          >
            <CursorHoverable className="inline-block">
              <a
                href="https://au.reachout.com/"
                rel="noopener noreferrer"
                target="_blank"
                className="block mt-v4 xs:mt-v1 hover-underline"
              >
                + Visit ReachOut
              </a>
            </CursorHoverable>
          </h3>
        </div>
      </div>
    </section>
  );
};

export default ReachOutCTA;
