import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Glide from "@glidejs/glide";
import { DocumentContext } from "~context/DocumentContext";
import CursorHoverable from "~components/CursorHoverable";

const Carousel = ({
  autoplay,
  buttons,
  className,
  items,
  keyPrefix,
  onUpdate
}) => {
  const { device, windowWidth } = useContext(DocumentContext);

  const glideRef = useRef();

  const [activeIndex, setActiveIndex] = useState(0);
  const [glide, setGlide] = useState(null);

  //

  const carouselLeft = () => {
    if (!glide) {
      return;
    }

    glide.go(`<`);
  };

  const carouselRight = () => {
    if (!glide) {
      return;
    }

    glide.go(`>`);
  };

  useEffect(() => {
    if (!glideRef?.current || !windowWidth || glide) {
      return;
    }

    const glideCarousel = new Glide(`#${keyPrefix}-glide`, {
      breakpoints: {
        768: {
          autoplay: false,
          gap: 0,
          hoverpause: false,
          peek: 0,
          perView: 1
        }
      },
      autoplay: autoplay > 0 ? autoplay : false,
      gap: 0,
      hoverpause: true,
      perView: 1,
      type: `carousel`
    });

    glideCarousel.on(`run`, () => {
      setActiveIndex(glideCarousel.index);

      onUpdate(glideCarousel.index);
    });

    glideCarousel.mount();

    setGlide(glideCarousel);
  }, [glideRef, windowWidth]);

  //

  return (
    <section
      key={`${keyPrefix}-container`}
      className={`carousel ${className || `w-full relative`} overflow-x-hidden`}
    >
      <div id={`${keyPrefix}-glide`} ref={glideRef} className="glide">
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides flex items-center justify-center">
            {items.map((itemJSX, itemIndex) => {
              const key = `${keyPrefix}-item-${itemIndex}`;

              return (
                <li key={key} className="glide__slide relative">
                  {itemJSX}
                </li>
              );
            })}
          </ul>
        </div>

        <div
          className="glide__bullets touch-only w-full absolute bottom-0 right-0 left-0 flex items-center justify-center pb-v8"
          data-glide-el="controls[nav]"
        >
          {items.map((_, bulletIndex) => {
            const buttonKey = `${keyPrefix}-bullet-${bulletIndex}`;

            return (
              <button
                key={buttonKey}
                type="button"
                className={`glide__bullet ${
                  activeIndex === bulletIndex ? `` : `opacity-25`
                } relative block rounded-full bg-white`}
                data-glide-dir={`=${bulletIndex}`}
              ></button>
            );
          })}
        </div>
      </div>
      {/* 
      {buttons && (
        <div className="w-full absolute bottom-0 right-0 left-0 z-10 flex items-center justify-between test">
          <CursorHoverable>
            <button
              type="button"
              className="w-1/4 relative block text-left f4"
              onClick={carouselLeft}
            >
              Previous
            </button>
          </CursorHoverable>

          <div className="w-1/2 relative flex items-center justify-center text-center">
            {activeIndex} of {items.length - 1}
          </div>

          <CursorHoverable>
            <button
              type="button"
              className="w-1/4 relative block text-right f4"
              onClick={carouselRight}
            >
              Next
            </button>
          </CursorHoverable>
        </div>
      )} */}
    </section>
  );
};

Carousel.defaultProps = {
  autoplay: 3000,
  buttons: false,
  className: ``,
  keyPrefix: `glide-carousel`,
  onUpdate: () => {}
};

Carousel.propTypes = {
  autoplay: PropTypes.number,
  buttons: PropTypes.bool,
  className: PropTypes.string,
  items: PropTypes.node.isRequired,
  keyPrefix: PropTypes.string,
  onUpdate: PropTypes.func
};

export default Carousel;
