/* eslint-disable camelcase */
/* eslint-disable react/prop-types */

import React, { useContext, useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import { AppContext } from "~context/AppContext";
import { DocumentContext } from "~context/DocumentContext";
import Button from "~components/Button";
import Carousel from "~components/Carousel";
import CursorHoverable from "~components/CursorHoverable";
import Footer from "~components/Footer";
import Layout from "~components/Layout";
import ReachOutCTA from "~components/ReachOutCTA";
import SEO from "~components/SEO";
import { pushProductEvent } from "~utils/analytics";
import {
  getInventoryIdByVariantTitle,
  getInventoryLevelsByIds,
  getProductByHandle,
  parseProducts
} from "~utils/shopify";
import { fancyError, shuffleArray } from "~utils/helpers";

const ProductPage = ({ data, location }) => {
  const {
    addToCart,
    darkMode,
    // inventoryCache,
    shuffledArtists,
    setShuffledArtists
  } = useContext(AppContext);
  const { device } = useContext(DocumentContext);

  // const { allShopifyAdminProduct, markdownRemark } = data;
  const { markdownRemark } = data;
  const { frontmatter } = markdownRemark;
  const products = parseProducts(data);
  const product = getProductByHandle(frontmatter.shopifyHandle, products);

  const [addableProduct, setAddableProduct] = useState({});
  const [descriptionExpanded, setDescriptionExpanded] = useState(false);
  // const [outOfStockVariants, setOutOfStockVariants] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [shuffledIndex, setShuffledIndex] = useState(null);
  const [shuffledNextLink, setShuffledNextLink] = useState(null);
  const [shuffledPrevLink, setShuffledPrevLink] = useState(null);
  const [touchContent, setTouchContent] = useState(`specs`);

  //

  useEffect(() => {
    if (!shuffledArtists) {
      if (
        !products[0]?.frontmatter?.image?.childImageSharp?.fluid ||
        !products[0]?.frontmatter?.hoverImage?.childImageSharp?.fluid
      ) {
        return;
      }

      const newlyShuffledArtists = shuffleArray(
        JSON.parse(JSON.stringify(products))
      );

      setShuffledArtists(newlyShuffledArtists);
    }
  }, []);

  useEffect(() => {
    if (!shuffledArtists) {
      return;
    }

    let artistIndex;

    shuffledArtists.forEach((shuffledArtist, shuffledArtistIndex) => {
      if (typeof artistIndex !== `undefined`) {
        return;
      }

      if (
        shuffledArtist.frontmatter.shopifyHandle ===
        product.frontmatter.shopifyHandle
      ) {
        artistIndex = shuffledArtistIndex;
      }
    });

    let nextLink;

    if (artistIndex === shuffledArtists.length - 1) {
      nextLink = shuffledArtists[0].slug;
    } else {
      nextLink = shuffledArtists[artistIndex + 1].slug;
    }

    let prevLink;

    if (artistIndex === 0) {
      prevLink = shuffledArtists[shuffledArtists.length - 1].slug;
    } else {
      prevLink = shuffledArtists[artistIndex - 1].slug;
    }

    setShuffledIndex(artistIndex);
    setShuffledNextLink(nextLink);
    setShuffledPrevLink(prevLink);
  }, [shuffledArtists]);

  //----------------------------------------------------------------------------
  //
  // onload hook

  useEffect(() => {
    const parsedProduct = getProductByHandle(
      frontmatter.shopifyHandle,
      products
    );

    const defaultVariant = parsedProduct.variants[0];
    const defaultSelectedOptions = {};

    defaultVariant.selectedOptions.forEach(option => {
      defaultSelectedOptions[option.name] = option.value;
    });
    parsedProduct.variant = defaultVariant;

    pushProductEvent(parsedProduct, `productView`);
    setAddableProduct(parsedProduct);
  }, []);

  //----------------------------------------------------------------------------
  //
  // product initialisation

  // useEffect(() => {
  //   if (!product || product.checkoutId) {
  //     return;
  //   }

  //   let inventoryIds = ``;

  //   product.variants.forEach((variant, variantIndex) => {
  //     const inventoryId = getInventoryIdByVariantTitle(
  //       allShopifyAdminProduct.edges,
  //       product.handle,
  //       variant.title
  //     );

  //     if (!inventoryId) {
  //       return;
  //     }

  //     variant.inventoryId = inventoryId;

  //     if (variantIndex === 0) {
  //       inventoryIds = inventoryId;
  //     } else {
  //       inventoryIds = `${inventoryIds},${inventoryId}`;
  //     }
  //   });
  // }, [product]);

  //----------------------------------------------------------------------------
  //
  // inventory

  // useEffect(() => {
  //   if (!inventoryCache) {
  //     return;
  //   }

  //   const unstockedVariants = [];

  //   Object.keys(inventoryCache).forEach(variantId => {
  //     if (inventoryCache[variantId] <= 0) {
  //       unstockedVariants.push(variantId);
  //     }
  //   });

  //   setOutOfStockVariants(unstockedVariants);
  // }, [inventoryCache]);

  //

  const ecommsJSX = (
    <div className="animation-appear-left-slow animation-delay-2 grid-end-9 xs:grid-end-24 grid-start-15 xs:grid-start-1 h-screen xs:h-auto sticky xs:relative top-0 xs:top-auto left-0 xs:left-auto pt-v12 overflow-y-auto">
      <article className="w-full relative">
        <h2 className="xs:mt-v12 b1 xs:text-center text-grey">
          {product.frontmatter.subheading}
        </h2>

        <h1 className="mt-v1 xs:mt-v3 f3 xs:text-center">
          {product.frontmatter.title}
        </h1>

        {product?.frontmatter?.artist !== `` && (
          <p
            className={`artist-page__name relative ${
              device === `desktop` ? `f5` : `b1 text-center`
            } xs:text-center`}
          >
            by {product.frontmatter.artist}
          </p>
        )}

        <h3 className="mt-v3 xs:mt-v7 f3 xs:text-center">
          ${product.variants[0].price}
        </h3>

        <div className="relative flex xs:block pt-v1">
          <Button
            className={`xs:w-3/4 relative xs:mt-v6 xs:mx-auto block pl-v3 pr-v3 ${
              product.frontmatter?.soldOut === true
                ? `pointer-events-none opacity-25`
                : ``
            }`}
            color={darkMode ? `white` : `black`}
            onClick={() => {
              if (product.frontmatter?.soldOut === true) {
                return;
              }

              addToCart(addableProduct, quantity);
            }}
          >
            <span className="b1">
              {product.frontmatter?.soldOut === true
                ? `Sold Out`
                : `Add to cart`}
            </span>
          </Button>

          <div className="relative ml-v2 xs:mt-v6 flex items-center justify-center">
            <CursorHoverable>
              <button
                type="button"
                className={`artist-page__quantity mr-v1 ${
                  quantity <= 1 ? `pointer-events-none opacity-50` : ``
                } f5`}
                onClick={() => {
                  if (quantity < 2) {
                    return;
                  }

                  setQuantity(quantity - 1);
                }}
              >
                —
              </button>
            </CursorHoverable>

            <h3 className="artist-page__quantity flex items-center justify-center f5">
              {quantity}
            </h3>

            <CursorHoverable>
              <button
                type="button"
                className="artist-page__quantity ml-v1 f5"
                onClick={() => setQuantity(quantity + 1)}
              >
                +
              </button>
            </CursorHoverable>
          </div>
        </div>

        {product.frontmatter.info && (
          <p
            className="mt-v1 xs:mt-v8 whitespace-pre-wrap b2 xs:text-center text-dark-grey"
            style={{ lineHeight: 1.2 }}
          >
            {product.frontmatter.info}
          </p>
        )}

        <div
          className={`artist-page__description ${darkMode ? `dark` : ``} ${
            descriptionExpanded ? `expanded` : ``
          } w-full relative mt-v4 xs:mt-v16 mb-v6`}
        >
          <h3 className="xs:mb-v4 b1 font-bold">Rationale</h3>

          {product?.frontmatter?.description && (
            <div className="relative">
              <div className="artist-page__description__text relative">
                <p className="whitespace-pre-wrap b1">
                  {product.frontmatter.description}
                </p>
              </div>

              <CursorHoverable>
                <div
                  role="presentation"
                  className="artist-page__description__bg transition-background w-full relative z-20 flex items-end justify-start"
                  onClick={() => setDescriptionExpanded(!descriptionExpanded)}
                >
                  <span className="block b1 text-left text-white">
                    {descriptionExpanded ? `Read less -` : `Read more +`}
                  </span>
                </div>
              </CursorHoverable>
            </div>
          )}
        </div>
      </article>
    </div>
  );

  //

  return (
    <>
      <SEO
        customTitle={frontmatter.title}
        customDescription={frontmatter.seoDescription}
        customKeywords={frontmatter.seoKeywords}
        path={location.pathname}
      />

      <Layout
        className={`artist-page w-full relative xs:pt-v1 transition-background bg-${
          darkMode ? `black` : `white`
        } text-${darkMode ? `white` : `black`}`}
      >
        {product && device && device === `desktop` && (
          <section className="artist-page__banner grid">
            <ul className="animation-appear-right-slow animation-delay-2 grid-end-12 grid-start-1 pt-v8">
              {frontmatter.gallery.map((gallery, galleryItem) => {
                const key = `gallery-key-${galleryItem}`;

                return (
                  <li key={key} className="relative mb-v4">
                    <figure className="w-full relative">
                      {gallery?.image?.childImageSharp?.fluid && (
                        <Img
                          className="w-full relative block"
                          fluid={gallery.image.childImageSharp.fluid}
                          alt={`${product.title} Item ${galleryItem}`}
                        />
                      )}
                    </figure>
                  </li>
                );
              })}
            </ul>

            {ecommsJSX}
          </section>
        )}

        {product && device && device !== `desktop` && (
          <section className="w-full relative block">
            <Carousel
              keyPrefix="mobile-artist-gallery"
              items={frontmatter.gallery.map((gallery, galleryIndex) => {
                return (
                  <figure className="w-full relative">
                    {gallery?.image?.childImageSharp?.fluid && (
                      <Img
                        className="w-full relative block"
                        fluid={gallery.image.childImageSharp.fluid}
                        alt={`${product.title} Item ${galleryIndex}`}
                      />
                    )}
                  </figure>
                );
              })}
            />
            <section className="grid">{product && ecommsJSX}</section>
          </section>
        )}

        <section className="desktop-only w-full relative block mt-v6 xs:mt-v20 mb-v12">
          <div className="grid">
            <article className="grid-end-10 xs:grid-end-24">
              <h3 className="f5">Specs</h3>
              <p className="mt-v3 whitespace-pre-wrap b1">
                {product.frontmatter.specs}
              </p>
            </article>

            <article className="grid-end-10 xs:grid-end-24 grid-start-13 xs:grid-start-1">
              <h3 className="f5">Shipping</h3>
              <p className="mt-v3 whitespace-pre-wrap b1">
                {product.frontmatter.shipping}
              </p>
            </article>
          </div>
        </section>

        <section className="touch-only w-full relative block mt-v6 xs:mt-v20 mb-v12">
          <div className="grid">
            <button
              type="button"
              className={`grid-end-12 ${
                touchContent === `specs` ? `underline` : ``
              } f5 text-left`}
              onClick={() => setTouchContent(`specs`)}
            >
              Specs
            </button>

            <button
              type="button"
              className={`grid-end-12 ${
                touchContent === `shipping` ? `underline` : ``
              } f5 text-left`}
              onClick={() => setTouchContent(`shipping`)}
            >
              Shipping
            </button>

            <div className="grid-end-24 mt-v12">
              {touchContent === `specs` && (
                <p className="animation-appear-down-slow animation-delay-1 whitespace-pre-wrap b1">
                  {product.frontmatter.specs}
                </p>
              )}

              {touchContent === `shipping` && (
                <p className="animation-appear-down-slow animation-delay-1 whitespace-pre-wrap b1">
                  {product.frontmatter.shipping}
                </p>
              )}
            </div>
          </div>
        </section>

        {shuffledArtists?.[0] && (
          <section className="w-full relative pb-v8">
            <div className="grid">
              <div className="grid-end-24 touch-only xs:mt-v12 mb-v2">
                <div className="w-full relative z-10 block b1">
                  {shuffledIndex + 1} of {shuffledArtists.length}
                </div>
              </div>

              <div className="grid-end-24 flex items-center">
                <CursorHoverable className="w-1/3 xs:w-full">
                  {(shuffledPrevLink && (
                    <Link
                      to={shuffledPrevLink}
                      className="w-full relative z-10 block f3"
                    >
                      Previous
                    </Link>
                  )) || <></>}
                </CursorHoverable>

                <div className="desktop-only w-full relative z-10 block b1 text-center">
                  {shuffledIndex + 1} of {shuffledArtists.length}
                </div>

                <CursorHoverable className="w-1/3 xs:w-full">
                  {(shuffledNextLink && (
                    <Link
                      to={shuffledNextLink}
                      className="w-full relative z-10 block f3 text-right"
                    >
                      Next
                    </Link>
                  )) || <></>}
                </CursorHoverable>
              </div>
            </div>
          </section>
        )}

        <ReachOutCTA />
      </Layout>

      <Footer />
    </>
  );
};

ProductPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({})
    })
  }).isRequired,
  location: PropTypes.shape({}).isRequired
};

export default ProductPage;

export const query = graphql`
  query ProductPage($handle: String!, $markdownId: String!) {
    markdownRemark(id: { eq: $markdownId }) {
      frontmatter {
        title
        shopifyHandle
        gallery {
          image {
            childImageSharp {
              fluid(maxWidth: 1280, quality: 75) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
            publicURL
          }
        }
        seoDescription
        seoKeywords
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "artist-page" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            shopifyHandle
            title
            soldOut
            subheading
            artist
            description
            image {
              childImageSharp {
                fluid(maxWidth: 1280, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
              publicURL
            }
            hoverImage {
              childImageSharp {
                fluid(maxWidth: 1280, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
              publicURL
            }
            info
            specs
            shipping
          }
        }
      }
    }
    shopifyProduct(handle: { eq: $handle }) {
      id
      handle
      title
      description
      images {
        originalSrc
      }
      productType
      vendor
      variants {
        id
        sku
        title
        image {
          originalSrc
        }
        price
        selectedOptions {
          name
          value
        }
      }
    }
    allShopifyProduct {
      edges {
        node {
          id
          title
          description
          handle
          images {
            originalSrc
          }
          variants {
            id
            sku
            title
            image {
              originalSrc
            }
            price
            selectedOptions {
              name
              value
            }
          }
        }
      }
    }
    allShopifyAdminProduct {
      edges {
        node {
          products {
            alternative_id
            handle
            variants {
              alternative_id
              title
            }
          }
        }
      }
    }
  }
`;
